<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-layout
      row
      wrap
      justify-center
    >
      <v-flex
        xs3
        pt-5
      >
        <v-alert
          :value="loginSuccess"
          type="success"
          class="my-0"
        >Успех</v-alert>
        <v-alert
          :value="loginError"
          type="error"
          class="my-0"
        >{{errorMessage}}</v-alert>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-text-field
            label="Логин"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="login"
          ></v-text-field>
          <v-text-field
            label="Пароль"
            :rules="[v => !!v || 'Заполните поле']"
            type="password"
            v-model="password"
          ></v-text-field>
          <v-select
            label="Магазин"
            :items="storeList"
            item-text="name"
            item-value="id"
            v-model="store_id"
            hide-details
            clearable
            no-data-text="Не надено"
          ></v-select>
          <v-btn
            color="info"
            @click="submitForm"
          >Войти</v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Login',
  data() {
    return {
      login: '',
      password: '',
      store_id: 1,
      storeList:[],
      loginSuccess: false,
      loginError: false,
      errorMessage: '',
    };
  },
  methods: {
    getStoreList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;

      axios
        .get(url)
        .then((response) => {
          // Add the additional store "All" to the response data
          const allStore = {
            id: 0,
            name: "All",
            description: "",
            created_by: null, // Set to null or any appropriate value
            created_at: null, // Set to null or any appropriate value
          };

          // Combine the additional store with the existing store list
          const storeListWithAll = [allStore, ...response.data];

          // Assign the modified store list to this.storeList
          this.storeList = storeListWithAll;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm() {
      this.loginSuccess = false;
      this.loginError = false;

      const validate = this.$refs.form.validate();
      if (validate) {
        const { login, password, store_id } = this;
        this.$store.dispatch('login', { login, password, store_id }).then(() => {
          const userGroup = this.$store.getters.getAuthUserGroup;
          this.loginSuccess = true;
          this.$router.push('/');
          // redirect to url according to user role
          // if (userGroup.code == 'courier' || userGroup.code == 'deliveryman' ) {
          //   this.$router.push('/deliveries/');  
          // } else {
          //   this.$router.push('/');
          // }
        }).catch((error) => {
          // add error exception
          this.loginError = true;
          if (error.response) {
            if (error.response.status === 400) {
                if (error.response.data && error.response.data.error) {
                  this.errorMessage = error.response.data.error;
                } else {
                  this.errorMessage = "Bad Request: Please check your input.";
                }
            }
          }
          // this.$router.push('/deliveries/');
        });
      }
    },
  },
  mounted() {
    this.getStoreList();
    this.$store.dispatch('autoAuth').then(() => {
      this.$router.push('/');
    });
  },
};
</script>
